<template>
  <DialogModal btnLabel="Aggiungi" title="Nuovo produttore distribuito" @close="$emit('close', $event)">
    <template #actions="{ close }">
      <div class="w-100">
        <button class="btn btn-primary" @click="closeModal(close)">Ok</button>
      </div>
    </template>

    <template #default>
      <SelectProducerWidget
        v-model="producer"
        label="Produttore da aggiungere"
        :producers="producers"
      />
    </template>
  </DialogModal>
</template>

<script>

import DialogModal from '@/libs/Modals/components/DialogModal.vue';
import SelectProducerWidget from '@/views/components/Form/Widgets/SelectProducerWidget.vue';

export default {
  components: { DialogModal, SelectProducerWidget },
  props: {
    producers: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      producer: null,
    };
  },
  methods: {
    closeModal (close) {
      this.$emit('producerSelected', this.producer);
      close();
    },
  },
};

</script>
