<template>
  <main v-if="dealer">
    <PageTitleContainer>
      <template #default>
        <PageTitle>{{ dealer.name }}</PageTitle>
      </template>
      <template #actions>
        <router-link class="btn btn-outline-primary" :to="{ name: 'suppliers.dealers.edit', params: { id: dealer.id } }">Modifica</router-link>
      </template>
    </PageTitleContainer>

    <div>
      <p><strong>Name</strong>: {{ dealer.name }}</p>
    </div>

    <div class="mt-3">
      <h4>Dati amministrativi</h4>
      <p>
        <strong>Ragione sociale</strong>: {{ dealer.companyName }}<br>
        <strong>Partita IVA</strong>: {{ dealer.vatCode }}<br>
        <strong>Codice fiscale</strong>: {{ dealer.fiscalCode }}<br>
        <strong>Indirizzo</strong>: {{ dealer.addressName }}<template v-if="dealer.addressNumber">, {{ dealer.addressNumber }}</template><br>
        <strong>Cap</strong>: {{ dealer.zipCode }}<br>
        <strong>Città</strong>: {{ dealer.city }} ({{ dealer.province }}) - {{ dealer.country }}<br>
        <strong>Numero di telefono</strong>: {{ dealer.phoneNumber || '-' }}<br>
        <strong>Indirizzo email</strong>: {{ dealer.emailAddress || '-' }}<br>
      </p>
    </div>

    <div class="mt-3">
      <h4>Note</h4>
      <p>
        {{ dealer.notes || '-' }}
      </p>
    </div>

    <div v-if="producers" class="mt-3">
      <h3>Produttori distribuiti</h3>
      <button v-if="missingProducers.length > 0" class="btn btn-secondary" @click="$openModal('add')">Aggiungi produttore distribuito</button>

      <AwlTable v-if="producers.length > 0" :heading="tableProducersHeadings" :rows="producers" striped>
        <template #actions-content="{ row }">
          <ActionDelete @click="removeProducer(row.id)" />
          <ActionShow class="ms-2" :to="{ name: 'suppliers.producers.show', params: { id: row.id } }" />
        </template>
      </AwlTable>
      <p v-else>Nessun produttore distribuito</p>

      <AddProducerToDealerModal v-if="$modals.add" :producers="missingProducers" @close="$closeModal('add')" @producerSelected="addProducer" />
    </div>

    <div v-if="wines" class="mt-3">
      <h4>Vini a catalogo</h4>

      <AwlTable v-if="wines.length > 0" :heading="tableWinesHeadings" :rows="wines" striped>
        <template #column-key-packageName="{ row }">
          {{ row.packageName }} {{ row.packageMaterial }}<br>
          {{ row.packageVolume / 1000 }}L
        </template>

        <template #column-key-purchasePrice="{ value }">
          {{ value / 100 }}€
        </template>

        <template #column-key-defaultSellingPrice="{ value }">
          {{ value / 100 }}€
        </template>

        <template #actions-content="{ row }">
          <ActionShow class="ms-2" :to="{ name: 'suppliers.wines.show', params: { id: row.id } }" />
        </template>
      </AwlTable>
      <p v-else>Nessun vino presente</p>
    </div>
  </main>
  <span v-else></span>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionShow from '@/libs/Table/components/Actions/ActionShow.vue';
import ActionDelete from '@/libs/Table/components/Actions/ActionDelete.vue';
import AddProducerToDealerModal from '@/views/components/Modals/AddProducerToDealerModal.vue';
import ModalsManagerMixin from '@/libs/Modals/mixins/manager.js';

function beforeRouteEnterOrUpdate (to, from, next) {
  if (!to?.params?.id) {
    return next(from);
  }

  Promise.all([
    $api.fetchDealer(to.params.id),
    $api.listProducersByDealer(to.params.id),
    $api.listProducers(),
    $api.getAllWinePackages({
      dealer: to.params.id,
    })
  ])
    .then(res => next(vm => {
      vm.dealer = res[0]?.data;
      vm.producers = res[1]?.data?.producers || [];
      vm.allProducers = res[2]?.data?.producers || [];
      vm.wines = res[3]?.data?.products || [];
    }))
    .catch(() => next(from))
  ;
}

export default {
  components: { PageTitleContainer, PageTitle, AwlTable, ActionShow, ActionDelete, AddProducerToDealerModal },
  mixins: [ ModalsManagerMixin ],
  data () {
    return {
      loading: false,
      dealer: null,
      producers: null,
      allProducers: null,
      wines: null,
    };
  },
  computed: {
    tableProducersHeadings () {
      return [
        { key: 'name', label: 'Nome' },
        { key: 'nbWines', label: 'Nb. prodotti' },
      ];
    },
    missingProducers () {
      return (this.allProducers || []).filter(ap => !this.producers.find(p => p.id === ap.id));
    },
    tableWinesHeadings () {
      return [
        { key: 'producerName', label: 'Produttore' },
        { key: 'wineName', label: 'Vino' },
        { key: 'wineTypeName', label: 'Tipo' },
        { key: 'vintageName', label: 'Anno' },
        { key: 'packageName', label: 'Contenitore' },
        { key: 'quantity', label: 'Quantità' },
        { key: 'purchasePrice', label: 'Prezzo acquisto' },
        { key: 'defaultSellingPrice', label: 'Prezzo vendita' },
      ];
    },
  },
  methods: {
    addProducer (producerId) {
      this.$api
        .addProducerToDealer(this.dealer.id, producerId)
        .then(res => {
          this.dealer = res.data;

          return Promise.all([
            this.$api.listProducersByDealer(this.dealer.id),
            this.$api.getAllWinePackages({
              dealer: this.dealer.id,
            }),
          ]);
        })
        .then(([resProducers, resWinePackages]) => {
          this.producers = resProducers?.data?.producers || [];
          this.wines = resWinePackages?.data?.products || [];
        })
      ;
    },
    removeProducer (producerId) {
      this.$api
        .removeProducerFromDealer(this.dealer.id, producerId)
        .then(res => {
          this.dealer = res.data;

          return Promise.all([
            this.$api.listProducersByDealer(this.dealer.id),
            this.$api.getAllWinePackages({
              dealer: this.dealer.id,
            }),
          ]);
        })
        .then(([resProducers, resWinePackages]) => {
          this.producers = resProducers?.data?.producers || [];
          this.wines = resWinePackages?.data?.products || [];
        })
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
